import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import getBrowserFingerprint from "get-browser-fingerprint";
import { browserName, isIOS } from "react-device-detect";
import axios from "axios";

const Trainingdata = () => {
  const fingerprint = getBrowserFingerprint();
  const [timestart, setTimestart] = useState(new Date());
  const history = useNavigate();
  const [lockedModules, setLockedModules] = useState([]);
  const [completedModules, setCompletedModules] = useState([]);
  const refs = useRef([]);
  const refsvals = useRef([]);
  const refFa = useRef([]);
  const refSpan = useRef([]);
  const refbtn = useRef([]);
  const refsdata = useRef([]);
  const refsclickables = useRef([]);
  const modal = useRef();
  const backdrop = useRef();
  const { t } = useTranslation(["trainingModule"]);
  Object.values(t("trainingModule", { returnObjects: true })).map((item) =>
    item.id !== "sequence1" ? (lockedModules[item.id] = true) : ""
  );
  const getdata = (id, field_type, key, segment) => {
    const jsondata = Object.values(t(segment, { returnObjects: true }));
    const data = jsondata.find((item) => {
      return item.id === id && item.fieldType === field_type && item.key !== "";
    });
    return data[key];
  };
  const handleCloseModal = () => {
    modal.current.className = "modal fade custom-pop-up";
    modal.current.style.display = "none";
    backdrop.current.className = "";
  };

  const createCompleteModuleBackup = (currentModule) => {
    if (localStorage.getItem("cMds")) {
      const cMdsArry = localStorage.getItem("cMds").split(",");
      if (!cMdsArry.includes(window.btoa(currentModule))) {
        if (completedModules.length === 0) {
          localStorage.removeItem("cMds");
          completedModules.push(cMdsArry);
          completedModules.push(window.btoa(currentModule));
          setCompletedModules(completedModules);
          localStorage.setItem("cMds", completedModules);
        } else {
          completedModules.push(window.btoa(currentModule));
          setCompletedModules(completedModules);
          localStorage.setItem("cMds", completedModules);
        }
      }
    } else {
      completedModules.push(window.btoa(currentModule));
      setCompletedModules(completedModules);
      localStorage.setItem("cMds", completedModules);
    }
  };
  const unlocknextModule = (val, currentModule, moduleName, lastmodule) => {
    createCompleteModuleBackup(currentModule);
    var timecheck = new Date();
    var seconds = (timecheck.getTime() - timestart.getTime()) / 1000;
    var timewillTake = 0;

    if (val === "" && lastmodule === true) {
      if (seconds < timewillTake) {
        modal.current.className = "modal fade custom-pop-up show";
        modal.current.style.display = "block";
        backdrop.current.className = "modal-backdrop fade show";
      } else {
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({
        //   event: "moduleCompleted",
        //   module: {
        //     section_name: moduleName,
        //     read_time: seconds + " seconds",
        //   },
        // });

        let module_step = currentModule.match(/\d+/)[0];
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "customEvent",
          eventCategory: "moduleCompleted",
          eventAction: "Module " + module_step,
          eventLabel: moduleName,
          eventReadTime: seconds + " seconds",
        });

        window.scrollTo(0, 0);
        getcertificate();
      }
    } else {
      if (seconds < timewillTake && refsvals.current[val].disabled === true) {
        modal.current.className = "modal fade custom-pop-up show";
        modal.current.style.display = "block";
        backdrop.current.className = "modal-backdrop fade show";
      } else {
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({
        //   event: "moduleCompleted",
        //   module: {
        //     section_name: moduleName,
        //     read_time: seconds + " seconds",
        //   },
        // });

        let module_step = currentModule.match(/\d+/)[0];
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "customEvent",
          eventCategory: "moduleCompleted",
          eventAction: "Module " + module_step,
          eventLabel: moduleName,
          eventReadTime: seconds + " seconds",
        });

        modal.current.className = "modal fade custom-pop-up";
        modal.current.style.display = "none";
        backdrop.current.className = "";

        makeActiveCurrentModule(currentModule);
        const trainingmoduleId = document.getElementById("trainingmoduleId");
        window.scrollTo({
          top: trainingmoduleId.offsetTop,
          behavior: "smooth",
        });
        refSpan.current[currentModule].textContent = "";
        setTimeout(
          () => (refSpan.current[currentModule].className = "tick"),
          800
        );

        setTimeout(() => {
          refsvals.current[val].disabled = false;
          refsvals.current[val].style.color = "#fff";
          refsclickables.current[val].style.zIndex = 0;
          refsvals.current[val].style.background =
            "linear-gradient(82.39deg, #562274 29.69%, #9A7AAB 116.32%)";
          refsvals.current[val].onclick = () => onbuttonClick(val, moduleName);
          lockedModules[val] = "";
          setLockedModules(lockedModules);
          setTimestart(new Date());
        }, 1200);
      }
    }
  };
  const makeActiveCurrentModule = (currentModule) => {
    refbtn.current[currentModule].disabled = true;
    refbtn.current[currentModule].textContent = getdata(
      "next_module_unlocked_button",
      "plain_text",
      "value",
      "unlockButtonContent"
    ).toUpperCase();

    refsvals.current[currentModule].style.color = "#562274";
    refsvals.current[currentModule].style.background = "#F7F4F8";
    refsvals.current[currentModule].className =
      "accordion-button border-0 collapsed afterIconActivate";
    refsdata.current[currentModule].className =
      "accordion-collapse border-0 collapse";
  };
  const getReadTime = (str) => {
    str = str.replace(/(^\s*)|(\s*$)/gi, "");
    str = str.replace(/[ ]{2,}/gi, " ");
    str = str.replace(/\n /, "\n");
    const wordcount = str.split(" ").length - 20;
    const wpm = 900;
    return (wordcount / wpm) * 60;
  };
  const checkDisabled = (currentModule) => {
    if (refsvals.current[currentModule].disabled === true) {
      modal.current.className = "modal fade custom-pop-up show";
      modal.current.style.display = "block";
      backdrop.current.className = "modal-backdrop fade show";


      let module_step = currentModule.match(/\d+/)[0];
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "customEvent",
        eventCategory: "Button",
        eventAction: "Click",
        eventLabel: "Not So Fast",
        eventValue: "Module "+module_step,
      });


    } else {
      refsclickables.current[currentModule].style.zIndex = 0;
      refsvals.current[currentModule].click();
    }
  };
  const onbuttonClick = (currentModule, currentModuleData) => {
    if(currentModule === "sequence1"){
      localStorage.setItem("mDsT", window.btoa(new Date()))
    }
    let module_step = currentModule.match(/\d+/)[0];

    let modulename = "";
    if (currentModuleData.moduleName) {
      modulename = currentModuleData.moduleName;
    } else {
      modulename = document
        .getElementById("itemsequence" + module_step)
        .getElementsByClassName("moduleheading")[0].innerText;
    }

    // console.log(module_step);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "customEvent",
      eventCategory: "Training Modules",
      eventAction: "Module " + module_step,
      eventLabel: modulename,
    });

    setTimeout(
      () =>
        refs.current[currentModule].scrollIntoView(
          { behavior: "smooth" },
          true
        ),
      600
    );
  };
  const getcertificate = () => {
    localStorage.setItem("PID", window.btoa(fingerprint + "P3"));
    window.location.reload();
  };
  const dynamicCreateCompleteModulesOnRefresh = (item) => {
    if (item !== "sequence6") {
      refsclickables.current[item].style.zIndex = 0;
      refsvals.current[item].style.color = "#fff";
      refsvals.current[item].style.background =
        "linear-gradient(82.39deg, #562274 29.69%, #9A7AAB 116.32%)";
      refbtn.current[item].click();
    } else {
      refSpan.current[item].textContent = "";
      refsvals.current[item].style.color = "#562274";
      refsvals.current[item].style.background = "#F7F4F8";
      refsvals.current[item].className =
        "accordion-button border-0 collapsed afterIconActivate";
      refsdata.current[item].className = "accordion-collapse border-0 collapse";
      setTimeout(() => {
        refSpan.current[item].className = "tick";
      }, 1300);
    }
  };

  const reverifyagencybyCode = async (e) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + "/varifyagency",
        {
          code: localStorage.getItem("aGencyCcod"),
        }
      );
      if(res.data != localStorage.getItem("aGencyID")){
        localStorage.removeItem("page");
        localStorage.removeItem("aGencyCcod");
        localStorage.removeItem("plain_text_BPA_CV");
        localStorage.removeItem("PID");
        localStorage.removeItem("plain_text_NAME_CV");
        localStorage.removeItem("aGencyID");
        localStorage.removeItem("cMds");
        localStorage.removeItem("plain_text_DATE_CV");
        localStorage.removeItem("plain_text_CSN_CV");
        history("/");
        window.location.reload();
      }
    } catch (error) {
      if (error.response) {
        localStorage.removeItem("page");
        localStorage.removeItem("aGencyCcod");
        localStorage.removeItem("plain_text_BPA_CV");
        localStorage.removeItem("PID");
        localStorage.removeItem("plain_text_NAME_CV");
        localStorage.removeItem("aGencyID");
        localStorage.removeItem("cMds");
        localStorage.removeItem("plain_text_DATE_CV");
        localStorage.removeItem("plain_text_CSN_CV");
        history("/");
        window.location.reload();
      }
    }
  };
  useEffect(() => {
    reverifyagencybyCode()
  }, []);

  useEffect(() => {
    if (browserName === "Safari" || isIOS) {
      const titleTopContent = document.querySelectorAll(".titleTopContent");
      if (titleTopContent.length) {
        titleTopContent.forEach((titleTopCont) => {
          titleTopCont.setAttribute("style", "font-family:urw_geometricbold");
        });
      }

      const headings = document.querySelectorAll(
        ".accordion-item .accordion-body h3"
      );
      if (headings.length) {
        headings.forEach((heading) => {
          heading.setAttribute("style", "letter-spacing:-0.05em");
        });
      }
      const moduleqoutes = document.querySelectorAll(
        ".accordion-item .accordion-body .module-qoute"
      );
      if (moduleqoutes.length) {
        moduleqoutes.forEach((moduleqoute) => {
          moduleqoute.setAttribute("style", "letter-spacing:-0.05em");
        });
      }
      const c1s = document.querySelectorAll(
        ".accordion-item .accordion-body .accordian_bottom .c_1"
      );
      if (c1s.length) {
        c1s.forEach((c1) => {
          c1.setAttribute("style", "letter-spacing:-0.05em");
        });
      }
    }

    const anchors = document.querySelectorAll(".accordion-body a");
    if (anchors.length) {
      if (anchors.length) {
        anchors.forEach((anchor) => {
          anchor.setAttribute("target", "_blank");
        });
      }
    }
  }, []);
  useEffect(() => {
    refsvals.current["sequence1"].style.color = "#fff";
    refsvals.current["sequence1"].style.background =
      "linear-gradient(82.39deg, #562274 29.69%, #9A7AAB 116.32%)";
    refsclickables.current["sequence1"].style.zIndex = 0;
    if (localStorage.getItem("cMds")) {
      const cMdsArry = localStorage.getItem("cMds").split(",");
      var i = 0;
      const loop = () => {
        setTimeout(() => {
          if (i < cMdsArry.length) {
            dynamicCreateCompleteModulesOnRefresh(window.atob(cMdsArry[i]));
            loop();
          }
          i++;
          //cMdsArry.map((item) => (
          //  dynamicCreateCompleteModulesOnRefresh(window.atob(item))
          //)
          //)
        }, 1200);
      };
      loop();
    }
  }, []);
  return (
    <>
      <section className="faq mb-5">
        <div className="container modulefullwidth">
          <div className="col-md-12 mx-auto bg-white">
            {Object.values(
              t("trainigModuleHeading", { returnObjects: true })
            ).map((item) =>
              item.fieldType === "h3" && item.value !== "" ? (
                <h3 id="trainingmoduleId" key={item.id} className="pb-4">
                  <ReactMarkdown components={{ p: React.Fragment }}>
                    {item.value}
                  </ReactMarkdown>
                </h3>
              ) : (
                ""
              )
            )}

            <div className="accordion accordion-flush " id="myAccordion">
              {Object.values(t("trainingModule", { returnObjects: true })).map(
                (item) => (
                  <div
                    key={item.id}
                    id={`item${item.id}`}
                    ref={(ref) => (refs.current[item.id] = ref)}
                    className="accordion-item"
                  >
                    <h2 className="accordion-header">
                      <div style={{ position: "relative" }}>
                        <div
                          ref={(ref) => (refsclickables.current[item.id] = ref)}
                          onClick={() => {
                            checkDisabled(item.id);
                          }}
                          id="clickable"
                        ></div>

                        <button
                          onClick={() => {
                            onbuttonClick(item.id, item);
                          }}
                          ref={(ref) => (refsvals.current[item.id] = ref)}
                          disabled={lockedModules[item.id]}
                          className="accordion-button collapsed border-0"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#" + item.id}
                          aria-expanded="false"
                          aria-controls={item.id}
                        >
                          <span ref={(ref) => (refSpan.current[item.id] = ref)}>
                            {item.moduleSeq}
                          </span>
                          <l className="moduleheading">{item.moduleName}</l>
                        </button>
                      </div>
                    </h2>
                    <div
                      id={item.id}
                      ref={(ref) => (refsdata.current[item.id] = ref)}
                      className="accordion-collapse collapse border-0"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="accordion-body ">
                        <div className="col-md-10">
                          {item.SecondLevelContent.map((item2) => (
                            <div key={item2.id} className="row mb-5">
                              <div className="col-2 col-md-1 num">
                                <h3 className="fw-bold">{item2.sequence}</h3>{" "}
                              </div>
                              <div className="col-12 col-md-11 px-4">
                                {item2.value ? (
                                  <h3 className="fw-bold mb-3">
                                    {item2.value}
                                  </h3>
                                ) : (
                                  ""
                                )}

                                {item2.innerContent.map((item3) =>
                                  item3.fieldType === "plain_text" ? (
                                    <p key={item3.id}>
                                      <ReactMarkdown
                                        components={{ p: React.Fragment }}
                                      >
                                        {item3.value}
                                      </ReactMarkdown>
                                    </p>
                                  ) : (
                                    ""
                                  )
                                )}

                                {item2.innerContent.map((item3) =>
                                  item3.fieldType === "bullet_points" ? (
                                    <p key={item3.id}>
                                      <ReactMarkdown
                                        components={{ p: React.Fragment }}
                                      >
                                        {item3.value}
                                      </ReactMarkdown>
                                    </p>
                                  ) : (
                                    ""
                                  )
                                )}

                                {item2.innerContent.map((item3) =>
                                  item3.fieldType === "quote_text" ? (
                                    <div
                                      key={item3.id}
                                      className="row mt-5 mb-3"
                                    >
                                      <div className="col-12 col-md-1"> </div>
                                      <div className="col-12 col-md-10">
                                        <span className="qouteSpan">
                                          &ldquo;
                                        </span>
                                        <h5 className="module-qoute" s>
                                          <ReactMarkdown
                                            components={{ p: React.Fragment }}
                                          >
                                            {item3.value}
                                          </ReactMarkdown>
                                        </h5>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                )}
                              </div>
                            </div>
                          ))}
                        </div>

                        {item.NextlevelImage.map((item2) => (
                          <div key={item2.id} className="row mb-5">
                            <div className="col-12">
                              <img src={item2.imgURL} alt="" />
                            </div>
                          </div>
                        ))}

                        <div className="row">
                          <div className="col-12">
                            <div className="accordian_bottom p-5">
                              <div className="row">
                                <div className="col-md-7 mx-auto text-center">
                                  {item.NextlevelContent.map((item2) =>
                                    item2.fieldType === "plain_text" ? (
                                      <span key={item2.id} className="c_1">
                                        <ReactMarkdown
                                          components={{ p: React.Fragment }}
                                        >
                                          {item2.value}
                                        </ReactMarkdown>
                                      </span>
                                    ) : (
                                      ""
                                    )
                                  )}

                                  {item.NextlevelContent.map((item3) =>
                                    item3.fieldType === "button" ? (
                                      <button
                                        key={item3.id}
                                        ref={(ref) =>
                                          (refbtn.current[item.id] = ref)
                                        }
                                        onClick={() =>
                                          unlocknextModule(
                                            item3.nextunlockModuleid,
                                            item.id,
                                            item.moduleName,
                                            item3.lastmodule
                                          )
                                        }
                                        className="faq-button-1 mx-auto mt-4"
                                      >
                                        {item3.value.toUpperCase()}
                                      </button>
                                    ) : (
                                      ""
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
      <div
        ref={modal}
        className="modal fade custom-pop-up"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content  p-4">
            <div className="modal-header">
              {Object.values(t("modalContent", { returnObjects: true })).map(
                (item) =>
                  item.fieldType === "modal_title" && item.value !== "" ? (
                    <h4
                      key={item.id}
                      className="modal-title"
                      id="exampleModalLabel"
                    >
                      <ReactMarkdown components={{ p: React.Fragment }}>
                        {item.value}
                      </ReactMarkdown>
                    </h4>
                  ) : (
                    ""
                  )
              )}
              <button
                onClick={handleCloseModal}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {Object.values(t("modalContent", { returnObjects: true })).map(
                (item) =>
                  item.fieldType === "modal_body" && item.value !== "" ? (
                    <p key={item.id}>
                      <ReactMarkdown components={{ p: React.Fragment }}>
                        {item.value}
                      </ReactMarkdown>
                    </p>
                  ) : (
                    ""
                  )
              )}
            </div>
            <div className="modal-footer">
              {Object.values(t("modalContent", { returnObjects: true })).map(
                (item) =>
                  item.fieldType === "modal_button" && item.value !== "" ? (
                    <button
                      key={item.id}
                      onClick={handleCloseModal}
                      className="faq-button-1 mx-auto"
                    >
                      <ReactMarkdown components={{ p: React.Fragment }}>
                        {item.value}
                      </ReactMarkdown>
                    </button>
                  ) : (
                    ""
                  )
              )}
            </div>
          </div>
        </div>
      </div>
      <div ref={backdrop} className=""></div>
    </>
  );
};

export default Trainingdata;
