
import React from 'react'
import LazyLoad from "react-lazyload";
import { useTranslation } from "react-i18next";
const FooterLogos = () => {
	const { t } = useTranslation(['Footer']);
	return (
		<section className="clients1 theme-space pt-0 px-lg-4">
			<div className="images-container container">
				<div className="row px-lg-5">

				{Object.values(t("footerLogos", { returnObjects: true })).map((item) => (
                        (item.fieldType === "assets" && item.imgURL !== "") ? 
                        <div key={item.id} className="item features-image col-4 col-md-2 card">
						<div className="item-wrapper">
							<div className="item-img">
								<LazyLoad height={200}>
									<img alt={item.alternateText} src={item.imgURL} />
								</LazyLoad>
							</div>
						</div>
					</div>
                        : ""
                    ))
                    }
				</div>
			</div>
		</section>

	)
}

export default FooterLogos
