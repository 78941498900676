import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import getBrowserFingerprint from "get-browser-fingerprint";
import { browserName, isIOS } from "react-device-detect";
import loadericon from "../assets/img/Rollingnew.svg";
import { Base64 } from 'js-base64';


const Traineedetails = () => {
  const fingerprint = getBrowserFingerprint();
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
  });
  const { t } = useTranslation(["certificationForm", "certificationView", "Footer"]);
  const history = useNavigate();
  const submitbutton = useRef();
  const [isloader, setIsloader] = useState(false);
  const [emailaddressrequired, setEmailaddressrequired] = useState(false);
  const [fullnamerequired, setFullnamerequired] = useState(false);
  const isValid = fullnamerequired === true && emailaddressrequired === true;

  
  const [btnDisable, setbtnDisable] = useState(true);
  const [submitted, setsubmitted] = useState(false);
  

  const getCaertificateTag = (id, key) => {
    const jsondata = Object.values(
      t("certificateContent", { returnObjects: true, ns: "certificationView" })
    );
    const data = jsondata.find((item) => {
      return item.id === id && item.key !== "";
    });
    return data[key];
  };
  
   const getFooterTag = (id, key) => {
    const jsondata = Object.values(
      t("LeftFooter", { returnObjects: true, ns: "Footer" })
    );
    const data = jsondata.find((item) => {
      return item.id === id && item.key !== "";
    });
    return data[key];
  };
  
  const getdata = (id, field_type, key, segment) => {
    const jsondata = Object.values(t(segment, { returnObjects: true }));
    const data = jsondata.find((item) => {
      return item.id === id && item.fieldType === field_type && item.key !== "";
    });
    return data[key];
  };
  const onSubmit = async (data) => {

    if(!submitted){
    //e.preventDefault();
    try {
     
     
      setbtnDisable(true);
        setIsloader(true);
        
        setsubmitted(true);
     
      const res = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + "/submittraineedata",
        {
          fullName: data["fullName"],
          emailAddress: data["emailAddress"],
          emailPromoterAgency: data["emailPromoterAgency"],
          idPromoterAgency: localStorage.getItem("aGencyID"),
          uniqueCodeUsed: localStorage.getItem("aGencyCcod"),
          idlanguage: localStorage.getItem("lanId"),
          useremail: t("UserEmail", { returnObjects: true }),
          agencypromoteremail: t("AgencyPromoterEmail", {
            returnObjects: true,
          }),

          HeaderTag1: getCaertificateTag(
            "h2_brandPromotor_tr_cert",
            "certificatetagTag1"
          ),
          HeaderTag2: getCaertificateTag(
            "h2_brandPromotor_tr_cert",
            "certificatetagTag2"
          ),
          NameTag: getCaertificateTag("plain_text_NAME_CV", "value"),
          DateTag: getCaertificateTag("plain_text_DATE_CV", "value"),
          CertifiacteNoTag: getCaertificateTag("plain_text_CSN_CV", "value"),
          AgencyNameTag: getCaertificateTag("plain_text_BPA_CV", "value"),
          EmailFooterDes: getFooterTag("rich_text_footer_PH1", "value")
        }
      );

        

      if (res && res.data.status === 1) {
        const plain_text_BPA_CV_WithCity =
          res.data.values.promoteragency.AgencyCity !== ""
            ? res.data.values.promoteragency.PromoterAgencyName +
              ",\n" +
              res.data.values.promoteragency.AgencyCity
            : res.data.values.promoteragency.PromoterAgencyName;

        const plain_text_BPA_CV =
          res.data.values.promoteragency.AgencyLocation !== ""
            ? res.data.values.promoteragency.AgencyCity !== ""
              ? plain_text_BPA_CV_WithCity +
                ", " +
                res.data.values.promoteragency.AgencyLocation
              : plain_text_BPA_CV_WithCity +
                ",\n" +
                res.data.values.promoteragency.AgencyLocation
            : plain_text_BPA_CV_WithCity;

        localStorage.setItem("PID", window.btoa(fingerprint + "P4"));

        localStorage.setItem(
          "plain_text_NAME_CV",res.data.values.fullName
        );
        localStorage.setItem(
          "plain_text_DATE_CV",
            Base64.encode(res.data.values.certificationDate)
        );
//        localStorage.setItem(
//          "plain_text_DATE_CV",
//          window.btoa(res.data.values.certificationDate)
//        );
        localStorage.setItem(
          "plain_text_CSN_CV",
            Base64.encode(res.data.values.certificateNumber)
        );
//        localStorage.setItem(
//          "plain_text_CSN_CV",
//          window.btoa(res.data.values.certificateNumber)
//        );
console.log('plain_text_BPA_CV');
console.log(plain_text_BPA_CV);
//        localStorage.setItem(
//          "plain_text_BPA_CV",
//          window.btoa(plain_text_BPA_CV)
//        );
        localStorage.setItem(
          "plain_text_BPA_CV",
          Base64.encode(plain_text_BPA_CV)
        );

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "customEvent",
          eventCategory: "Button",
          eventAction: "Button/Page View",
          eventLabel: "GOAL",
          eventValue: "Successful",
        });

        history("/");
        window.location.reload();
      }
    } catch (error) {
        
        
      setbtnDisable(false);
        setIsloader(false);
        
        setsubmitted(false);
    
    
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "customEvent",
        eventCategory: "Button",
        eventAction: "Button/Page View",
        eventLabel: "GOAL",
        eventValue: "Error",
      });
      if (error.response) {
        // console.log(error.response.data.msg);
      }
    }
    }
  };

  useEffect(() => {
    if (browserName === "Safari" || isIOS) {
      const strongs = document.querySelectorAll("strong");
      if (strongs.length) {
        strongs.forEach((strong) => {
          strong.setAttribute("style", "font-family:urw_geometricbold");
        });
      }
    }
  }, []);

  useEffect(() => {
	var currenttime = new Date();
        var startedtime  = new Date(window.atob(localStorage.getItem("mDsT")))
        var checkseconds = (currenttime.getTime() - startedtime.getTime()) / 1000;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "customEvent",
          eventCategory: "Button",
          eventAction: "Traniee Details Page",
          eventLabel: "Training Completed",
          eventValue: checkseconds + " seconds",
        });
        localStorage.removeItem("mDsT")
	
	  
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="header2 theme-space px-lg-5 autofocus">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-10 col-lg-10">
              <div className="text-wrapper">
                {Object.values(t("topContent", { returnObjects: true })).map(
                  (item) =>
                    item.fieldType === "h1" && item.value !== "" ? (
                      <h1 key={item.id} className="mb-5">
                        <ReactMarkdown components={{ p: React.Fragment }}>
                          {item.value}
                        </ReactMarkdown>
                      </h1>
                    ) : (
                      ""
                    )
                )}
                {Object.values(t("topContent", { returnObjects: true })).map(
                  (item) =>
                    item.fieldType === "plain_text" && item.value !== "" ? (
                      <p
                        key={item.id}
                        className="col-12 col-md-5 pe-lg-5 me-lg-5 page3topplainteext"
                      >
                        <ReactMarkdown components={{ p: React.Fragment }}>
                          {item.value}
                        </ReactMarkdown>
                      </p>
                    ) : (
                      ""
                    )
                )}
              </div>
            </div>
            <div className="col-12 col-md-2 col-lg-2"> </div>
          </div>
        </div>
      </section>
      <section className="theme-space px-lg-5 mb-3">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-5">
              <div className="text-wrapper">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <>
                    <div className="position-relative">
                      <label htmlFor="fullName">
                        <ReactMarkdown components={{ p: React.Fragment }}>
                          {getdata(
                            "fullName",
                            "input_text",
                            "title",
                            "formContent"
                          )}
                        </ReactMarkdown>
                      </label>
                      <input
                        {...register("fullName", {
                          required: true,
                          minLength: 2,
                          //pattern: /^[a-zA-Z ]*$/,
                          //pattern: /^[a-zA-Z]+[^\s]+(?:$|.*[^\s]+$)/,
                          pattern: /^(?! )(?!.* $)(?!.* {2})[^<>~!#/?_$^%&+=\[\]*()]+$/gm,

                          onChange: (e) => {
                            if (e.target.value === "") {
                              setFullnamerequired(false);
                              setbtnDisable(true);
                              clearErrors("fullName");
                            } else {
                              setFullnamerequired(true);
                              if(emailaddressrequired){
                                setbtnDisable(false);
                              }
                              
                            }
                          },
                        })}
                        maxLength={30}
                        className="w-100 px-3 py-2 mb-4"
                        placeholder={getdata(
                          "fullName",
                          "input_text",
                          "placeholder",
                          "formContent"
                        )}
                        autoComplete="off"
                        id="fullName"
                      />

                      {errors.fullName?.type === "pattern" && (
                        <span className="error">
                          {getdata(
                          "fullName",
                          "input_text",
                          "errorText",
                          "formContent"
                        )}
                        </span>
                      )}


                    {errors.fullName?.type === "minLength" && (
                        <span className="error">
                          {getdata(
                          "fullName",
                          "input_text",
                          "errorText",
                          "formContent"
                        )}
                        </span>
                      )}
                    </div>

                    <div className="position-relative">
                      <label htmlFor="emailAddress">
                        <ReactMarkdown components={{ p: React.Fragment }}>
                          {getdata(
                            "emailAddress",
                            "input_email",
                            "title",
                            "formContent"
                          )}
                        </ReactMarkdown>
                      </label>
                      <input
                        {...register("emailAddress", {
                          required: true,
                          //pattern:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                          pattern:/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                          onChange: (e) => {
                            console.log(e.target.value);
                            if (e.target.value === "") {
                              setEmailaddressrequired(false);
                              setbtnDisable(true);
                              clearErrors("emailAddress");
                             
                            } else {
                              setEmailaddressrequired(true);
                              clearErrors("emailAddress");

                              if(fullnamerequired){                               
                                setbtnDisable(false);
                              }
                            }
                          },
                        })}
						maxLength={75}
                        className="w-100 px-3 py-2 mb-4"
                        placeholder={getdata(
                          "emailAddress",
                          "input_email",
                          "placeholder",
                          "formContent"
                        )}
                        autoComplete="off"
                        id="emailAddress"
                      />

                      {errors.emailAddress?.type === "pattern" && (
                        <span className="error">
                          {getdata(
                          "emailAddress",
                          "input_email",
                          "errorText",
                          "formContent"
                        )}
                        </span>
                      )}
                    </div>

                    <div className="position-relative mb-4">
                      <label htmlFor="emailPromoterAgency">
                        <ReactMarkdown components={{ p: React.Fragment }}>
                          {getdata(
                            "emailPromoterAgency",
                            "input_email",
                            "title",
                            "formContent"
                          )}
                        </ReactMarkdown>
                      </label>
                      <input
                        {...register("emailPromoterAgency", {
                          //pattern:/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
						  pattern:/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                          onChange: (e) => {
                            e.target.value === "" ||
                            errors.emailPromoterAgency?.type === "pattern"
                              ? (e.target.className = "w-100 px-3 py-2 input3rd")
                              : (e.target.className = "w-100 px-3 py-2 input3rd");
                          },
                        })}
						maxLength={75}
                        className="w-100 px-3 py-2 input3rd"
                        placeholder={getdata(
                          "emailPromoterAgency",
                          "input_email",
                          "placeholder",
                          "formContent"
                        )}
                        autoComplete="off"
                        id="emailPromoterAgency"
                      />

                      {errors.emailPromoterAgency?.type === "pattern" && (
                        <span className="error error3rd">
                          {getdata(
                          "emailPromoterAgency",
                          "input_email",
                          "errorText",
                          "formContent"
                        )}
                        </span>
                      )}
                    </div>
                  </>

                  {Object.values(t("formContent", { returnObjects: true })).map(
                    (item) =>
                      item.fieldType === "button" && item.value !== "" ? (
                        <button
                          aria-label={item.value}
                          ref={(ref) => (submitbutton.current = ref)}
                          disabled={btnDisable}
                          key={item.id}
                          className="faq-button">                       
                          <ReactMarkdown components={{ p: React.Fragment }}>
                            {item.value}
                          </ReactMarkdown> {isloader ? <img className="loaderbtnimg" width={25}  height={25} src={loadericon} />: ""}
                    
                        </button>
                      ) : (
                        ""
                      )
                  )}
                  
                  <br />
                 
                </form>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-7 image-wrapper"> </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Traineedetails;
