import React, {useEffect } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import getBrowserFingerprint from 'get-browser-fingerprint';
const NotFoundpage = () => {
    const fingerprint = getBrowserFingerprint();
    const history = useNavigate();  
    const restorePage = () => {
        if(localStorage.getItem('PID')){
            localStorage.setItem('PID', window.btoa(fingerprint + 'P2'));
            history('/');
            window.location.reload();
        }
       
    }
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event':'404',
        'pagePath': '/404'+window.location.pathname+window.location.search,
        'is404':'true'
        });
      }, []);

    return (
        <section className="header3 theme-space px-lg-5 mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-9 mx-auto text-center mb-5"><span>We can't find the page you're looking for</span></div>
                    <div className="col-md-4 mx-auto text-center"><Link onClick={restorePage} to="/" className="back_home mx-auto">BACK TO HOME</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default NotFoundpage
