import React, { useRef, Suspense, useEffect } from "react";
//import axios from "axios";
import axios from "axios-jsonp-pro";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import getBrowserFingerprint from "get-browser-fingerprint";
import { useForm } from "react-hook-form";
const UniqueCode = () => {
  const {
    register,
    setError,
    setFocus,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm();
  const { ref } = register("code");
  const refs = useRef();
  const unquiebutton = useRef();
  const fingerprint = getBrowserFingerprint();
  const { t } = useTranslation(["UniqueLandingPage"]);
  const history = useNavigate();

  const verifyagencybyCode = async (data) => {
    //e.preventDefault();

    try {
      const res = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + "/varifyagency",
        {
          code: data.code,
        }
      );
      localStorage.setItem("PID", window.btoa(fingerprint + "P2"));
      localStorage.setItem("aGencyID", res.data);
      localStorage.setItem("aGencyCcod", data.code);
      history("/");



      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "customEvent",
        eventCategory: "ValidCode",
        eventAction: "Click",
        eventLabel: "startTraining",
        eventValue: data.code,
        eventTime: Math.floor(Date.now() / 1000),
      });


      window.location.reload();
    } catch (error) {
      if (error.response) {
        setError("code", { type: "unique" });
        refs.current.className = "w-100 px-3 py-2 mb-5 redFocus";
        setFocus("code");
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "customEvent",
          eventCategory: "InValidCode",
          eventAction: "Click",
          eventLabel: "startTraining",
          eventValue: data.code,
        });
      }
    }
  };
  
  useEffect(async() => {
    try {
      const res = await axios.jsonp(
        "https://web.diageoagegate.com/api/country.php"
      ).then((response) => {
        if(response.countryCode==='TR') {
          const clients = document.querySelectorAll(".clients1");
            if (clients.length) {
              clients.forEach((client) => {
                client.setAttribute("style", "display:none");
            });
            }
        }else{
          const clients = document.querySelectorAll(".clients1");
          if (clients.length) {
            clients.forEach((client) => {
              client.setAttribute("style", "display:block");
          });
          }
        }
      });
    } catch (error) {
      if (error.response) {
      }
    }	
	});
  return (
    <Suspense fallback={null}>
      <section className="header2 theme-space px-lg-5 autofocus">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9 col-lg-9">
              <div className="text-wrapper">
                {Object.values(t("topContent", { returnObjects: true })).map(
                  (item) =>
                    item.fieldType === "h1" && item.value !== "" ? (
                      <h1 key={item.id} className="mb-5">
                        <ReactMarkdown components={{ p: React.Fragment }}>
                          {item.value}
                        </ReactMarkdown>
                      </h1>
                    ) : (
                      ""
                    )
                )}
                {Object.values(t("topContent", { returnObjects: true })).map(
                  (item) =>
                    item.fieldType === "plain_text" && item.value !== "" ? (
                      <p key={item.id} className="col-12 col-md-7 col-lg-7">
                        <ReactMarkdown components={{ p: React.Fragment }}>
                          {item.value}
                        </ReactMarkdown>
                      </p>
                    ) : (
                      ""
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="theme-space px-lg-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-5">
              <div className="text-wrapper">
                <form onSubmit={handleSubmit(verifyagencybyCode)}>
                  {Object.values(
                    t("bottomContent", { returnObjects: true })
                  ).map((item) =>
                    item.fieldType === "input_text" && item.value !== "" ? (
                      <div key={item.id} className="position-relative">
                        <label htmlFor="uniquedid" className="mb-3 uniqueid">
                          <ReactMarkdown components={{ p: React.Fragment }}>
                            {item.value}
                          </ReactMarkdown>
                        </label>

                        <input
                          id="uniquedid"
                          {...register("code", {
                            required: true,
                            onChange: (e) => {
                              if (e.target.value === "") {
                                refs.current.className = "w-100 px-3 py-2 mb-5";
                                unquiebutton.current.disabled = true;
                              } else {
                                refs.current.className = "w-100 px-3 py-2 mb-5";
                                unquiebutton.current.disabled = false;
                                clearErrors("code");
                              }
                            },
                          })}
                          ref={(e) => {
                            ref(e);
                            refs.current = e;
                          }}
                          className="w-100 px-3 py-2 mb-5"
                          placeholder={item.placeholder}
                          autoComplete="off"
                        />

                        {errors.code?.type === "unique" && (
                          <span className="error_uniquecode">
                            {item.errorText}
                          </span>
                        )}
                      </div>
                    ) : (
                      ""
                    )
                  )}
                  {Object.values(
                    t("bottomContent", { returnObjects: true })
                  ).map((item) =>
                    item.fieldType === "button" && item.value !== "" ? (
                      <button
                        aria-label={item.value}
                        ref={(ref) => (unquiebutton.current = ref)}
                        disabled={true}
                        key={item.id}
                        className="faq-button"
                      >
                        <ReactMarkdown components={{ p: React.Fragment }}>
                          {item.value}
                        </ReactMarkdown>
                      </button>
                    ) : (
                      ""
                    )
                  )}
                </form>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-7"> </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
};
export default UniqueCode;
