import React, {useEffect } from 'react';
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import ReactMarkdown from 'react-markdown'
import { Link } from "react-router-dom";
import { browserName, isIOS } from 'react-device-detect';
const Footer = () => {
	const { t } = useTranslation(['Footer']);
	useEffect(() => {
        if(browserName === "Safari" || isIOS){
            const  footerlies =  document.querySelectorAll('.media-container-row ul li a')
                if(footerlies.length) {
                    footerlies.forEach((footerli) => {
                        footerli.setAttribute('style', 'font-family:urw_geometricbold')
                      })
                }        
        }
    }, []);
	return (
		<section id="footersection" className="footer foot-space">
			<div className="container">
				<div className="row pb-3">
					<div className="col-sm-8 col-md-8 col-lg-8 col-xl-5 pe-lg-6">
						<div className="item-wrapper">

							{Object.values(t("LeftFooter", { returnObjects: true })).map((item) => (
								(item.fieldType === "assets" && item.imgURL !== "") ?
									<LazyLoad className="mb-4" key={item.id} height={100}>
										<img height="100" src={item.imgURL} alt={item.alternateText} className="footer-logo-assests" />
									</LazyLoad>

									: ""
							))
							}


							{Object.values(t("LeftFooter", { returnObjects: true })).map((item) => (
								(item.fieldType === "rich_text" && item.value !== "") ?
									<p key={item.id} className="">
										<ReactMarkdown components={{ p: React.Fragment }}>{item.value}</ReactMarkdown>
									</p>
									: ""
							))
							}

						</div>
					</div>
					<div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 pe-lg-5">
						<div className="item-wrapper mt-3 mt-lg-5">
							{Object.values(t("centerFooter", { returnObjects: true })).map((item) => (
								(item.fieldType === "h3" && item.value !== "") ?
									<div key={item.id} className="mailheading">
										<ReactMarkdown components={{ p: React.Fragment }}>{item.value}</ReactMarkdown>
									</div>
									: ""
							))
							}

							{Object.values(t("centerFooter", { returnObjects: true })).map((item) => (
								(item.fieldType === "email_link" && item.URL !== "") ?
									<p key={item.id} className="foot-mail">

										<Link
											to='#'
											onClick={(e) => {


												window.dataLayer = window.dataLayer || [];
												window.dataLayer.push({
												  event: "customEvent",
												  eventCategory: "Button",
												  eventAction: "Click",
												  eventLabel: "contact_email",
												  eventValue: '',
												});



												window.location.href = 'mailto:'+item.URL;
												e.preventDefault();
											}}
										>
											<ReactMarkdown components={{ p: React.Fragment }}>{item.URL}</ReactMarkdown>
										</Link>

									</p>
									: ""
							))
							}
						</div>
					</div>
					<div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 pe-lg-5">
						<div className="item-wrapper">
							{Object.values(t("rightFooter", { returnObjects: true })).map((item) => (
								(item.fieldType === "assets" && item.imgURL !== "") ?
									<LazyLoad className="mb-4" key={item.id} height={100}>
										<img height="100" src={item.imgURL} alt={item.alternateText} className="footer-logo-assests" />
									</LazyLoad>

									: ""
							))
							}


							{Object.values(t("rightFooter", { returnObjects: true })).map((item) => (
								(item.fieldType === "plain_text" && item.value !== "") ?
									<p key={item.id} className="">
										<ReactMarkdown components={{ p: React.Fragment }}>{item.value}</ReactMarkdown>
									</p>
									: ""
							))
							}

							{Object.values(t("rightFooter", { returnObjects: true })).map((item) => (
								(item.fieldType === "link" && item.URL !== "") ?
									<a key={item.id} href={item.URL} className="drinkiq">
										<ReactMarkdown components={{ p: React.Fragment }}>{item.title}</ReactMarkdown>
									</a>
									: ""
							))
							}


						</div>
					</div>
				</div>
				<div className="media-container-row">
					<div id="footer"></div>
				</div>

			</div>
		</section>

	)
}

export default Footer
