import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbarmenu from './components/Elements/Navbar';
import Footer from './components/Elements/Footer';
import Dashboard from "./components/Dashboard";
import UniqueCodeByUrl from "./components/UniqueCodeByUrl";
import NotFoundpage from "./components/NotFoundpage";
import FooterLogos from "./components/Elements/FooterLogs";
function App() {
  document.title =  process.env.REACT_APP_NAME_TITLE_SUFFIX;
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Navbarmenu />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/:code" element={<UniqueCodeByUrl/>} />
          <Route path="*" element={<NotFoundpage/>} />
        </Routes>
        <FooterLogos />
        <Footer />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
