import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./assets/css/style.css";
import './assets/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

