import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Base64 } from 'js-base64';


const Certificate = () => {
    const history = useNavigate();
    const [certificatefields, setCertificatefields] = useState([]);
    const {t} = useTranslation(['certificationView']);

//	Object.values(t('certificateContent', { returnObjects: true })).map((item) => 
//	(item.fieldType === "plain_text" && item.value !== "")?
//			(item.id === 'plain_text_NAME_CV')? certificatefields[item.id] = localStorage.getItem(item.id)
//			:
//		certificatefields[item.id] = window.atob(localStorage.getItem(item.id))
//		:
//		""
//	)
    Object.values(t('certificateContent', {returnObjects: true})).map((item) =>
        (item.fieldType === "plain_text" && item.value !== "") ?
                (item.id === 'plain_text_NAME_CV') ? certificatefields[item.id] = localStorage.getItem(item.id)
                :
                certificatefields[item.id] = Base64.decode(localStorage.getItem(item.id))
                :
                ""
    )
    useEffect(() => {

        /* var currenttime = new Date();
         var startedtime  = new Date(window.atob(localStorage.getItem("mDsT")))
         var checkseconds = (currenttime.getTime() - startedtime.getTime()) / 1000;
         
         window.dataLayer = window.dataLayer || [];
         window.dataLayer.push({
         event: "customEvent",
         eventCategory: "Button",
         eventAction: "Certificate Page View",
         eventLabel: "Training Completed",
         eventValue: checkseconds + " seconds",
         });
         localStorage.removeItem("mDsT") */

        setCertificatefields(certificatefields);
        localStorage.removeItem("cMds")
    }, [certificatefields, t]);
    useEffect(() => {
        if (localStorage.getItem("lanCode") === 'ko' || localStorage.getItem("lanCode") === 'ja' || localStorage.getItem("lanCode") === 'tcc' || localStorage.getItem("lanCode") === 'th' || localStorage.getItem("lanCode") === 'vi') {

            const h2titles = document.querySelectorAll(".box-3 h2");
            if (h2titles.length) {

                h2titles.forEach((h2title) => {
                    h2title.style.setProperty('line-height', "60px", "important")

                });
            }
        }
    });

    const reverifyagencybyCode = async (e) => {
        try {
            const res = await axios.post(
                    process.env.REACT_APP_API_ENDPOINT + "/varifyagency",
                    {
                        code: localStorage.getItem("aGencyCcod"),
                    }
            );
            if (res.data != localStorage.getItem("aGencyID")) {
                localStorage.removeItem("page");
                localStorage.removeItem("aGencyCcod");
                localStorage.removeItem("plain_text_BPA_CV");
                localStorage.removeItem("PID");
                localStorage.removeItem("plain_text_NAME_CV");
                localStorage.removeItem("aGencyID");
                localStorage.removeItem("cMds");
                localStorage.removeItem("plain_text_DATE_CV");
                localStorage.removeItem("plain_text_CSN_CV");
                history("/");
                window.location.reload();
            }
        } catch (error) {
            if (error.response) {
                localStorage.removeItem("page");
                localStorage.removeItem("aGencyCcod");
                localStorage.removeItem("plain_text_BPA_CV");
                localStorage.removeItem("PID");
                localStorage.removeItem("plain_text_NAME_CV");
                localStorage.removeItem("aGencyID");
                localStorage.removeItem("cMds");
                localStorage.removeItem("plain_text_DATE_CV");
                localStorage.removeItem("plain_text_CSN_CV");
                history("/");
                window.location.reload();
            }
        }
    };
    useEffect(() => {
            reverifyagencybyCode()
        }, []);


        return (
                <>  

<section className="header2 theme-space1 px-lg-5 autofocus">
    <div className="container">
        <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-7 col-lg-7">
                <div className="text-wrapper">
                    
        { Object.values(t("topContent", {returnObjects: true})).map((item) => (
                    (item.fieldType === "h1" && item.value !== "") ? <h1 key={item.id} className="mb-5"><ReactMarkdown components={{p: React.Fragment}}>{item.value}</ReactMarkdown></h1> : ""
                    ))
                    }
        
                    { Object.values(t("topContent", { returnObjects: true })).map((item) => (
            (item.fieldType === "plain_text" && item.value !== "")?<p key={item.id} className="col-md-10"><ReactMarkdown components={{p: React.Fragment}}>{item.value}</ReactMarkdown></p>:""
            ))
                    }
    
                </div>
            </div>
            <div className="col-12 col-md-5 col-lg-5 image-wrapper"> </div>
        </div>
    </div>
</section>
<section className="px-lg-5 pt-0 mb-5">
    <div className="container">
        <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 mx-auto">
                <div className="box-3">
                    <div className="row flex-column-reverse">
                        <div className="col-md-6">


                            
    { Object.values(t("certificateContent", { returnObjects: true })).map((item) => (
            (item.fieldType === "h2" && item.value !== "")?<h2 key={item.id} className="text-white ps-3 py-4 ps-lg-5 py-lg-4 certificatetitle"><ReactMarkdown components={{p: React.Fragment}}>{item.value}</ReactMarkdown></h2>:""
            ))
                            }
    

                        </div>
                        <div className="col-md-6"> <img src="bpassets/images/logo2.png" alt="" className="me-5 my-4 page-img"/> </div>
                    </div>
                    <div className="row flex-column-reverse">
                        <div className="col-md-5"> 
                            
    { Object.values(t("certificateContent", { returnObjects: true })).map((item) => (
            (item.fieldType === "assets" && item.imgURL !== "")?<img className="certificateImage" key={item.id} src={item.imgURL} alt={item.alternateText} />:""
            ))
                            }
    

                        </div>
                        <div className="col-md-7">
                            <div className="text-white box-6 px-4">

                                
    { Object.values(t("certificateContent", { returnObjects: true })).map((item) => (
            (item.fieldType === "plain_text" && item.value !== "")?<h3 key={item.id}><span><ReactMarkdown components={{p: React.Fragment}}>{item.value.toUpperCase()}</ReactMarkdown></span><span style={{ "line-height": "normal" }}>{certificatefields[item.id]}</span></h3>:""
            ))
                                }
    

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</>
);
};
export default Certificate
