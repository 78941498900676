import React, { useEffect } from 'react'
import Trainingdata from '../components/Elements/Trainingdata'
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown'

const Training = () => {
	const { t } = useTranslation(['trainingModule']);
	const goTotrainingModule = (trainingmoduleId) =>{
        const violation = document.getElementById(trainingmoduleId); 
        window.scrollTo({
          top:violation.offsetTop,
          behavior:"smooth"
      });
    };


	 window.onbeforeunload = function () {
		window.scrollTo(0, 0);
	  }
	  
		useEffect(() => {
		if(localStorage.getItem("lanCode") === 'el' || localStorage.getItem("lanCode") === 'fr' || localStorage.getItem("lanCode") === 'pt-br' || localStorage.getItem("lanCode") === 'es' || localStorage.getItem("lanCode") === 'th' || localStorage.getItem("lanCode") === 'nl' || localStorage.getItem("lanCode") === 'in' || localStorage.getItem("lanCode") === 'sw' || localStorage.getItem("lanCode") === 'it'|| localStorage.getItem("lanCode") === 'vi') {
			const h1topContents = document.querySelectorAll(".h1topContent");
			  if (h1topContents.length) {
				h1topContents.forEach((h1topContent) => {
					if(localStorage.getItem("lanCode") === 'th'){
						h1topContent.classList.remove("greek_h1");
						h1topContent.classList.add("thai_h1");
						h1topContent.classList.remove("mb-5");
						const titleTopContent = document.querySelectorAll(".titleTopContent");
						titleTopContent[0].style.setProperty('margin-top',"30px","important")
					}else{
						h1topContent.classList.add("greek_h1");
						h1topContent.classList.remove("thai_h1");
						const titleTopContent = document.querySelectorAll(".titleTopContent");
						titleTopContent[0].style.setProperty('margin-top',"1rem","important")
					}

					
				});
			  }
		}else{
			const h1topContents = document.querySelectorAll(".h1topContent");
			  if (h1topContents.length) {
				h1topContents.forEach((h1topContent) => {
					h1topContent.classList.remove("greek_h1");
					h1topContent.classList.remove("thai_h1");
					const titleTopContent = document.querySelectorAll(".titleTopContent");
					titleTopContent[0].style.setProperty('margin-top',"1rem","important")
				});
			  }
		}
	});

	return (
		<>
			<section className="header1 theme-space px-lg-5 autofocus">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-6">
							<div className="text-wrapper">
								{Object.values(t("topContent", { returnObjects: true })).map((item) => (
									(item.fieldType === "h1" && item.value !== "") ? <h1 key={item.id} className="mb-5 w-480 h1topContent"><ReactMarkdown components={{ p: React.Fragment }}>{item.value}</ReactMarkdown></h1> : ""
								))
								}

								{Object.values(t("topContent", { returnObjects: true })).map((item) => (
									(item.fieldType === "h6" && item.value !== "") ? <div key={item.id} className="mb-3 titleTopContent"><ReactMarkdown components={{ p: React.Fragment }}>{item.value}</ReactMarkdown></div> : ""
								))
								}

								{Object.values(t("topContent", { returnObjects: true })).map((item) => (
									(item.fieldType === "plain_text" && item.value !== "") ? <p key={item.id} className="col-md-9 training_p"><ReactMarkdown components={{ p: React.Fragment }}>{item.value}</ReactMarkdown></p> : ""
								))
								}

								<img onClick={() => {goTotrainingModule("trainingmoduleId")}} alt="" src={process.env.REACT_APP_ASSETS_URL + "/images/down-arrow-black.png"} style={{ width: "23px" }} className="pt-4 downarrow" /> </div>
						</div>
						<div className="col-12 col-md-6 col-lg-6 image-wrapper">
						<div className="mbr-img-wrap ps-lg-5"> 

								{Object.values(t("topContent", { returnObjects: true })).map((item) => (
									(item.fieldType === "assets" && item.imgURL !== "") ?<img key={item.id} src={item.imgURL} alt="" />  : ""
								))
								}

						</div>
							
							
						</div>
					</div>
				</div>
			</section>
			<Trainingdata></Trainingdata>
		</>
	);
};
export default Training
