import React, { useEffect } from "react";
import UniqueCode from './UniqueCode';
import Training from './Training';
import Traineedetails from './Traineedetails';
import Certificate from './Certificate';
import getBrowserFingerprint from 'get-browser-fingerprint';

const Dashboard = ({page}) => {
    const fingerprint = getBrowserFingerprint();
    if(localStorage.getItem('PID')){
        page = window.atob(localStorage.getItem('PID'));
    }

    const pageName = () => {      
        switch(page) {  
            case fingerprint+'P2':
                return "Training module page"
            case fingerprint+'P3':
                return "Trainee details form page"
            case fingerprint+'P4':
                return "Certificate view page"
            default:
                return "Unique code landing page"
        }
    }
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "customEvent",
          eventCategory: "Page Change",
          eventAction: pageName(page),
          eventLabel: pageName(page),
        });
      }, []);

    return (
        <>
             {(() => {
                switch (page) {  
                case    fingerprint+'P2':
                    return (<Training/>)
                case    fingerprint+'P3':
                    return (<Traineedetails  />)
                case    fingerprint+'P4':
                    return (<Certificate />)
                default:
                    return (<UniqueCode/>)
                }
      })()}
        </>
    );
};
export default Dashboard
