import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import LanguageSelector from "../LanguageSelector";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import getBrowserFingerprint from "get-browser-fingerprint";

const Navbarmenu = () => {
  const fingerprint = getBrowserFingerprint();
  const location = useLocation();
  const { t } = useTranslation(["Nav"]);
  const [navs, setNavs] = useState([]);
  const history = useNavigate();
  const refs = useRef();
  const refmenu = useRef();
  const restorePage = () => {
    if (localStorage.getItem("PID")) {
      localStorage.setItem("PID", window.btoa(fingerprint + "P2"));
      history("/");
      window.location.reload();
    }
  };

  function getVerticalScrollPercentage(elm) {
    var p = elm.parentNode;
    return (
      ((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) * 100
    );
  }

  useEffect(() => {
    document.onscroll = function () {
      var pos = getVerticalScrollPercentage(document.body);
      pos = Math.round(pos);

      if (pos == 25 || pos == 50 || pos == 75 || (pos >= 90 && pos <= 92)) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "customEvent",
          eventCategory: "Scroll Depth",
          eventAction: window.location.pathname,
          eventLabel: "Scroll Depth",
          eventValue: pos + "%",
        });
      }
    };
  });

  const goTofootersection = (footersection) => {
    const violation = document.getElementById(footersection);
    refmenu.current.className = "collapse navbar-collapse";
    refs.current.className = "";
    window.scrollTo({
      top: violation.offsetTop,
      behavior: "smooth",
    });
  };

  const menutoggle = (e) => {
    refs.current.className === "open"
      ? (refs.current.className = "")
      : (refs.current.className = "open");
  };
  useEffect(() => {
    setNavs(Object.values(t("Nav", { returnObjects: true })));
  }, [t]);
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        {Object.values(t("Nav", { returnObjects: true })).map((item) =>
          item.fieldType === "assets" && item.value !== "" ? (
            <Link
              key={item.id}
              className="navbar-brand"
              onClick={restorePage}
              to={item.path}
            >
              <img
                height="120"
                src={item.imgURL}
                alt={item.title}
                aria-label={item.title}
                className="logo-diageo"
              />
            </Link>
          ) : (
            ""
          )
        )}
        <button
          aria-label="Select Language"
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-content"
        >
          <div className="hamburger-toggle">
            <div
              className="hamburger"
              ref={(ref) => (refs.current = ref)}
              onClick={() => menutoggle()}
              id="nav-icon4"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </button>
        <div
          ref={(ref) => (refmenu.current = ref)}
          className="collapse navbar-collapse"
          id="navbar-content"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 mobilefullmenu">
            {navs.map((item) =>
              item.fieldType === "link" && item.value !== "" ? (
                <li
                  key={item.id}
                  className={
                    location.pathname === item.path
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  {" "}
                  <span
                    onClick={() => {
                      goTofootersection("footersection");
                    }}
                    className="nav-link"
                    aria-label={item.value}
                  >
                    {item.value}
                  </span>
                </li>
              ) : (
                ""
              )
            )}
            <LanguageSelector />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbarmenu;
