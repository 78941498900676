import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: (localStorage.getItem('lanCode'))?localStorage.getItem('lanCode'):"en",   //default language
        fallbackLng: "en", //when specified language translations not present 
        //then fallbacklang translations loaded.
        debug: false,
       
        backend: {
            /* translation file path */
            loadPath: process.env.REACT_APP_ASSETS_URL + "/i18n/{{lng}}/{{ns}}.json",
            crossDomain: true
        },
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ["UniqueLandingPage"],
        defaultNS: "UniqueLandingPage",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            wait: true
            
        },
    });
export default i18n;