import React from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import getBrowserFingerprint from "get-browser-fingerprint";
const UniqueCodeByUrl = () => {
  const fingerprint = getBrowserFingerprint();
  const history = useNavigate();
  const { code } = useParams();
  console.log(code);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "customEvent",
    eventCategory: "Event",
    eventAction: "URL",
    eventLabel: "Agency_Code",
    eventValue: code,
  });

  const verifyagencybyCode = async (e) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + "/varifyagency",
        {
          code: code,
        }
      );
      localStorage.setItem("PID", window.btoa(fingerprint + "P2"));
      localStorage.setItem("aGencyID", res.data);
      localStorage.setItem("aGencyCcod", code);
      history("/");
    } catch (error) {
      if (error.response) {
        localStorage.setItem("page", "");
        history("/");
      }
    }
  };
  verifyagencybyCode();
  return <></>;
};
export default UniqueCodeByUrl;
